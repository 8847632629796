<!-- Order -->
<template>
 <!--begin: Orders-->
<div class="card sales_order" v-loading="loading">
 <div class="card-body p-0">
      <!--begin: Orders-->     
        <h3 class="mb-10 font-weight-bold text-dark">
          <i class="flaticon-background" style="font-size: 20px"></i>
          {{ title_text }}
        </h3>
     <!--begin: Orders Form-->
    <el-form ref="form"  auto-complete="false">
    <div class="row">
      <div class="col-xl-8">
          <el-row>
              <el-col :span="6">
                <el-form-item label="Company Name" prop="customer_id">
                  <el-select
                    ref="customer_name"
                    v-model="form.customer_id"
                    filterable
                    clearable
                    remote
                    @change="getCustomerAddress($event)"
                    placeholder="Type something to search customer"
                    style="width: 100%"
                    :remote-method="searchCustomer"
                    @clear="setValueNull"
                    :disabled="this.$route.params.estimation_id ? true : false"
                    v-if="!this.$route.params.estimation_id"
                  >
                    <el-option class="add-container" value="">
                      <a class="add-drop-btn" @click="createCustomer">
                        <i class="el-icon-circle-plus"></i> Add Customer
                      </a>
                    </el-option>
                    <el-option
                      v-for="(customer, index) in customer_list"
                      :key="index"
                      :label="customer.company_name ? customer.company_name :
                        customer.first_name +
                        ' ' +
                        (customer.last_name == null
                          ? ''
                          : customer.last_name)
                      "
                      :value="customer.id"
                      style="height: auto; padding: 10px 20px"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    v-model="customer_name"
                    @input.native="capitalize"
                    v-else
                    disabled
                  ></el-input>
                  <!-- <span v-if="form.customer_typeId" style="font-size:10px"
                    >(Customer Type : <b>{{ form.customer_type }}</b>)</span
                  > -->
                  <el-span
                    class="form-err"
                    v-if="form.errors.has('customer_id')"
                    >{{ form.errors.get("customer_id") }}</el-span
                  >
                </el-form-item>
              </el-col>
              <el-col :span="5" class="ml-5">
                <div ref="shiping_method">
                  <el-form-item
                    :label="'Shipping Method*'"
                    prop="shiping_method"
                  >
                    <el-select
                      filterable
                      v-model="form.shiping_method"
                      placeholder="Select"
                      class="dropdown-add"
                      clearable
                      @change="shippedChange(form.shiping_method)"
                      :class="
                        form.errors.has('shiping_method')
                          ? 'required'
                          : 'required' 
                      "
                    >
                      <el-option class="add-container" value="">
                        <a class="add-drop-btn" @click="CreateShipping()">
                          <i class="el-icon-circle-plus"></i> Add
                        </a>
                      </el-option>
                      <el-option
                        v-for="(shippmethod, index) in shippingmethod"
                        :key="index"
                        :label="shippmethod.shipping_name"
                        :value="shippmethod.shipping_name"
                        auto-complete="nope"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <span
                  class="form-err"
                  v-if="
                    form.errors.has('shiping_method') &&
                    !form.shiping_method
                  "
                  >{{ form.errors.get("shiping_method") }}</span
                >
              </el-col>
              <el-col :span="5" class="ml-5">
                <el-form-item
                  label="Delivery/PickUp Date"
                  prop="delivery_date"
                  style="margin-bottom: -2px"
                ></el-form-item>
                <el-date-picker
                  v-model="form.delivery_date"
                  placeholder="DD-MM-YYYY"
                  type="date"
                  format="yyyy-MM-dd"
                  style="width: 100%"
                  :picker-options="role_id == 1 ? '' : pickerOptions"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="6" class="ml-5">
                <div ref="shipping_address_id">
                  <el-form-item prop="shipping_address_id" :label="'Shipping Address*'">                        
                    <el-select
                      clearable
                      filterable
                      v-model="form.shipping_address_id"
                      placeholder="Select Address"
                      :disabled="
                        !form.customer_id ||
                        form.shiping_method == 'Store Pickup'
                      "
                      :allow-create="true"
                      style="width: 100%"
                      @change="addShipping($event)"
                      auto-complete="nope"
                      @clear="shippingClear()"
                      :class="
                        form.errors.has('shipping_address_id')
                          ? 'required'
                          : 'required'
                      "
                    >
                      <el-option class="add-container" value="">
                        <a class="add-drop-btn" @click="createAddress(2)">
                          <i class="el-icon-circle-plus"></i> Add Address
                        </a>
                      </el-option>
                      <el-option
                        v-for="address in shippingAddresses"
                        :key="address.id"
                        :label="addressLabel(address)"
                        :value="address.id"
                      ></el-option>
                    </el-select>
                    <span
                    class="form-err"
                      v-if="
                        form.errors.has('shipping_address_id') &&
                        form.shiping_method != 'Store Pickup'
                      "
                      >shipping address is required</span
                    >
                  </el-form-item>
                </div>
              </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" >
            <el-form-item label="Delivery Type" prop="delivery_type">
              <el-select
                filterable
                v-model="form.delivery_type"
                placeholder="Select"
                :class="form.errors.has('delivery_type') ? 'required' : 'required'"
                clearable
                :disabled="form.shiping_method == 'Store Pickup'"
              >
                <el-option class="add-container" value="">
                  <a class="add-drop-btn" @click="CreateDeliveryType()">
                    <i class="el-icon-circle-plus"></i> Add
                  </a>
                </el-option>
                <el-option
                  v-for="(del_type, index) in deliveryType"
                  :key="index"
                  :label="del_type.type_name"
                  :value="del_type.type_name"
                  auto-complete="nope"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <span
              class="form-err"
              v-if="form.errors.has('delivery_type') && form.shiping_method != 'Store Pickup' && !form.delivery_type"
              >The Delivery type field is required.</span
            >
          </el-col>
          <el-col :span="5" class="ml-5" >
            <el-form-item label="Ship Via" prop="ship_via">
              <el-select
                v-model="form.ship_via"
                placeholder="Select"
                clearable
                :disabled="form.shiping_method == 'Store Pickup'"
              >
                <el-option class="add-container" value="">
                  <a class="add-drop-btn" @click="CreateShipVia()">
                    <i class="el-icon-circle-plus"></i> Add
                  </a>
                </el-option>
                <el-option
                  v-for="(shipvia, index) in shippingvia"
                  :key="index"
                  :label="shipvia.ship_type"
                  :value="shipvia.ship_type"
                  auto-complete="off"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <span class="form-err" v-if="form.errors.has('ship_via')">{{
              form.errors.get("ship_via")
            }}</span>
          </el-col>
          <el-col :span="5" class="ml-5">
            <el-form-item :label="'Order Taken By*'" prop="order_type">
              <el-input v-model="form.order_taken_by" @input.native="capitalize"
                  :class="form.errors.has('order_taken_by')? 'required': 'required'"
                ></el-input>
            </el-form-item>
            <span class="form-err" v-if="form.errors.has('order_taken_by') && !form.order_taken_by">{{ form.errors.get("order_taken_by") }}</span>
          </el-col>
          <el-col :span="24" class="row">
            <div class="col-xl-6">
        <div style="font-weight:bold">Credits Details</div>
        <el-row class="credit_title">
          <el-col :span="5" class="text_size">
                <div><b>Credits</b></div>
                <div class="ml-1">
                  <b>$ </b>
                  <span style ="color: green">{{ parseFloat(user_credits).toFixed(2)}}</span>
                </div>
              </el-col>
              <el-col :span="5" :offset="1" class="text_size" >
                <div><b>Available </b></div>
                <div class="ml-1">
                  <b>$ </b>
                  <span style="color: green">{{parseFloat(available_credit).toFixed(2)}}</span>
                </div>
              </el-col>
              <el-col :span="5" :offset="1" class="text_size">
                <div><b>Balance</b></div>
                <div class="ml-1">
                  <b>$ </b>
                  <span style="color: red">{{ parseFloat(balance_credit).toFixed(2)}}</span>
                </div>
              </el-col>
              <el-col :span="6" :offset="1" class="text_size" v-if="form.creditmemo">
                <div><b>CreditMemo </b></div>
                <div class="ml-1">
                  <b>$ </b>
                  <span style="color: green">{{parseFloat(form.creditmemo.balance).toFixed(2)}}</span>
                </div>
              </el-col>
        </el-row>
      </div>
      <div class="col-xl-2">
        <div style="font-weight:bold">Aging</div>
        <el-row class="credit_title">
          <el-col>
          <span>{{form.last_credit}}</span>
          </el-col>
        </el-row>
      </div>
      <div class="col-xl-4">
          <div style="font-weight:bold">Note</div>
          <el-input
                type="textarea"
                :rows="2"
                v-model="form.note"
                @input.native="capitalize">
          </el-input>
      </div>
          </el-col>
          </el-row>       
      </div>
      <div class="col-xl-4">
        <div class="second_text_field">
          <el-row >
            <el-col :span="7" >
              <el-form-item>
                <div @click="customer_po = !customer_po" class="text_header_field">Customer P.O #</div>
                <el-input v-model="form.customer_po" v-show="customer_po"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7" :offset="1">
              <el-form-item >
                <div @click="store = !store" class="text_header_field">Store</div>
                <el-select
                    clearable
                    filterable
                    v-model="form.website_id"
                    placeholder="Select Website"
                    style="width: 100%"
                    :allow-create="true"
                    :disabled="form.customer_id ? true : false"
                    v-show="store"
                  >
                    <el-option
                      v-for="website in active_website"
                      :key="website.website_id"
                      :label="website.website_name"
                      :value="website.website_id"
                    ></el-option>
                  </el-select>
              </el-form-item>            
            </el-col>
            <el-col :span="8" :offset="1">
              <el-form-item >
              <div @click="ref_by = !ref_by" class="text_header_field">Referred  By</div>
                  <el-input v-model="form.ref_by" @input.native="capitalize" v-show="ref_by"></el-input>
            </el-form-item>
            </el-col> 
            </el-row>
            <el-row>
            <el-col :span="7" style="margin-top:-10px;">
              <el-form-item >
              <div @click="created_date = !created_date" class="text_header_field">Order Date</div>
              <el-date-picker
                        v-model="form.created_date"
                        placeholder="DD-MM-YYYY"
                        type="date"
                        format="yyyy-MM-dd"
                        style="width: 100%"
                        :disabled="this.$route.params.estimation_id ? true : false"
                        v-show="created_date"
                      >
              </el-date-picker>
            </el-form-item>
            </el-col> 
            <el-col :span="7" :offset="1" style="margin-top:-10px;">
              <el-form-item >
              <div @click="billing_address_show = !billing_address_show" class="text_header_field">Billing Address <span style="color:red">*</span>
                <span style="color:red"
                  v-if="
                    form.errors.has('billing_address_id') &&
                    !form.billing_address_id">billing address required</span>
              </div>
              <el-select

                  v-show="billing_address_show"
                  clearable
                  filterable
                  v-model="form.billing_address_id"
                  placeholder="Select Address"
                  :disabled="!form.customer_id"
                  style="width: 100%"
                  :allow-create="true"
                  @change="addBilling($event)"
                  auto-complete="nope"
                  :class="
                    form.errors.has('billing_address_id')
                      ? ' required'
                      : 'required'
                  "
                >
                  <el-option class="add-container" value="">
                    <a class="add-drop-btn" @click="createAddress(1)">
                      <i class="el-icon-circle-plus"></i> Add Address
                    </a>
                  </el-option
                  >
                  <el-option
                    v-for="address in billingAddresses"
                    :key="address.id"
                    :label="addressLabel(address)"
                    :value="address.id"
                  ></el-option>
                </el-select>
            </el-form-item>
            </el-col> 
            <el-col :span="8" :offset="1" style="margin-top:-10px;">
              <el-form-item >
              <div @click="project_name = !project_name" class="text_header_field">Project Name</div>
              <el-input v-model="form.project_name" @input.native="capitalize" v-show="project_name"></el-input>
            </el-form-item>
            </el-col> 
          </el-row>
          <el-row>
            <el-col :span="7" style="margin-top:-10px;">
              <el-form-item >
              <div @click="onsite_contact = !onsite_contact" class="text_header_field">Onsite Contact</div>
              <el-input v-model="form.onsite_contact" @input.native="capitalize" v-show="onsite_contact"></el-input>
            </el-form-item>
            </el-col> 
            <el-col :span="7" :offset="1" style="margin-top:-10px;">
              <el-form-item >
              <div @click="order_type = !order_type" class="text_header_field">Order Type</div>
              <el-select v-show="order_type"
                    v-model="form.order_type"
                    placeholder="Select"
                    clearable
                  >
                <el-option
                  v-for="(type, index) in ordertype"
                  :key="index"
                  :label="type.text"
                  :value="type.text"
                  auto-complete="off"
                >
                </el-option>
              </el-select>
            </el-form-item>
            </el-col> 
            <el-col :span="8" :offset="1" style="margin-top:-10px;margin-bottom: -10px;">
              <el-form-item >
              <div @click="order_by = !order_by" class="text_header_field">Order Place by
                <div style="margin-top:-18px">(customer)</div>
              </div>
              <el-input  v-model="form.order_by" @input.native="capitalize" v-show="order_by"></el-input>
            </el-form-item>
            </el-col> 
          </el-row>
          <el-row>
            <el-col :span="7" style="margin-top:-10px;">
              <el-form-item >
              <div @click="sale_person = !sale_person" class="text_header_field">Sales Person</div>
              <el-input v-model="form.sale_person" @input.native="capitalize" v-show="sale_person"></el-input>
            </el-form-item>
            </el-col> 
            <el-col :span="7" :offset="1" style="margin-top:-10px;">
              <el-form-item >
              <div @click="shipping_number = !shipping_number" class="text_header_field">Site Contact</div>
              <el-input v-model="form.shipping_number" @input.native="capitalize" v-show="shipping_number"></el-input>
            </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
     
      <div class="col-xl-5">
      </div>           
    </div>
    <!--table start-->
    <div class="row mt-5">
      <div class="col-xl-12">
        <div style="font-weight:bold">Products  
          <i  @click.prevent="createProduct" class="el-icon-circle-plus icon-pointer" ></i>
        <el-form-item>
          <el-table :data="form.products" stripe border fit>
              <el-table-column label="" width="50">
                <template slot-scope="scope">
                  <i
                    v-if="
                      scope.$index == form.products.length - 1 &&
                      scope.$index > 0
                    "
                    @click="removeProduct(scope.$index)"
                    class="el-icon-circle-close icon-pointer icon-close"
                  ></i>
                  <i
                    v-if="scope.$index == form.products.length - 1 && add_product_btn == 0"
                    @click="addProduct(scope.$index)"
                    class="el-icon-circle-plus icon-pointer"
                  ></i>
                  <i
                    v-else
                    @click="removeProduct(scope.$index)"
                    class="el-icon-circle-close icon-pointer icon-close"
                  ></i>
                </template>
              </el-table-column>
              <el-table-column label="SKU #" width="120">
                <template slot-scope="scope">
                  <el-select
                    filterable
                    remote
                    clearable
                    v-model="form.products[scope.$index].product_id"
                    :remote-method="searchProduct"
                    placeholder="Select"
                    style="width: 100%"
                    @change="setProductPrice(scope.$index)"
                  >
                    <el-option
                      v-for="(product, index) in product_price"
                      :key="index"
                      :label="product.sku"
                      :value="product.id"
                      auto-complete="off"
                      style="height: auto; padding: 10px 20px"
                      :disabled="isExist(product.id)"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="Product Name" min-width="200">
                <template slot-scope="scope">
                  <el-select
                    filterable
                    remote
                    clearable
                    v-model="form.products[scope.$index].product_id"
                    :remote-method="searchProduct"
                    placeholder="Select"
                    style="width: 100%,height:100px"
                    @change="setProductPrice(scope.$index)"
                  >
                    <el-option
                      v-for="(product, index) in product_price"
                      :key="index"
                      :label="product.name + '-' + product.sku"
                      :value="product.id"
                      auto-complete="off"
                      style="height: auto; padding: 10px 20px"
                      :disabled="isExist(product.id)"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="Inventory"
                width="90"
                align="center"
              >
                <template slot-scope="scope">
                  <span
                    v-if="form.shiping_method == 'Direct Shipping'"
                  ></span>
                  <span v-else class="text_size">{{
                    form.products[scope.$index].stock_quantity
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Type" width="60">
                <template slot-scope="scope">
                  <span class="text_size">{{ form.products[scope.$index].type }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Quantity" width="100">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="form.products[scope.$index].quantity"
                    placeholder="0"
                    :min="form.products[scope.$index].product_id ? 1 : 0"
                    :step="1"
                    @change="setProductPrice(scope.$index, $event)"
                    :controls="false"
                  ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
                label="Unit Price"
                width="80"
                align="left"                        
              >
                <template slot-scope="scope" >
                  <div v-if="role_id == 1">
                  <span
                    v-if="form.products[scope.$index].product_id == '71'"
                  >
                    <el-input-number
                      v-model="form.products[scope.$index].regular_price"
                      :controls="false"
                      @change="setProductPrice(scope.$index)"
                    ></el-input-number>
                  </span>
                  <span v-else>
                    <div
                      v-if="
                        form.products[scope.$index].regular_price ==
                        form.products[scope.$index].unit_price
                      "
                    >
                      <div class="text_size">
                        ${{ parseFloat(form.products[scope.$index].regular_price).toFixed(2) }}
                      </div>
                    </div>
                    <div v-else>
                      <div style="margin-bottom: -10px">
                        <s class="text_size"
                          >${{
                            parseFloat(form.products[scope.$index].regular_price).toFixed(2)
                          }}</s
                        >
                      </div>
                      <div class="text_size">
                      ${{ parseFloat(form.products[scope.$index].unit_price).toFixed(2) }}
                      </div>
                    </div>
                  </span>
                  </div>
                  <div v-else>
                      <div v-if="form.products[scope.$index].regular_price == form.products[scope.$index].unit_price">
                        <div class="text_size">
                          ${{ parseFloat(form.products[scope.$index].regular_price).toFixed(2) }}
                        </div>
                      </div>
                      <div v-else>
                        <div style="margin-bottom: -10px">
                        <s class="text_size">${{parseFloat(form.products[scope.$index].regular_price).toFixed(2)}}</s>
                        </div>
                      <div class="text_size">${{ parseFloat(form.products[scope.$index].unit_price).toFixed(2) }}</div>
                      </div>
                      </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Total Price"
                width="100"
                align="left"
              >
                <template slot-scope="scope">
                  <span v-loading = "price_loading" element-loading-spinner="el-icon-loading" class="text_size"
                    >${{ parseFloat(form.products[scope.$index].total_price).toFixed(2) }}
                    <el-tooltip class="item" effect="light" content="Extra Price Details" placement="top">
                      <i class="el-icon-info info-price"  @click="ExtraPrice(scope)" v-if ="form.products[scope.$index].quantity > 0"></i>
                    </el-tooltip></span>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
            <span class="form-err" v-if="show_product_error"
              >Please select a product.</span>
            <span class="form-err" v-if="show_product_qty_error"
              >Please quantity must be grater than 0.</span>
            <span class="form-err">{{this.price_error}}</span>
            <div style="float:right; padding: 10px;" v-if="form.total_quantity>0">
              <span style="font-weight:600">Total Items  :</span>
              <span> {{form.total_quantity}}</span>
            </div>
        </div>
      </div>
    </div>
    <!-- table end -->
    <div class="row">
      <div class="col-xl-6">
        <div style="font-weight:bold" class="mb-3">Other Comments or Special Instructions</div>
        <el-input type="textarea" :rows="3" v-model="form.comments" @input.native="capitalize" ></el-input>
      </div>
      <div class="col-xl-3"></div>
      <div class="col-xl-3">
        <el-form
            :label-position="labelPosition"
            label-width="150px"
          >
            <el-form-item label="Sub Total" prop="sub_total">
              <span class="price_total_tx">{{form.sub_total}}</span>                    
            </el-form-item>
            <el-form-item
              label="Carry In Service"
              prop="inside_total"
            >
            <span class="price_total_tx">{{form.inside_price_total}}</span> 
            </el-form-item>
            <el-form-item label="Saving" prop="discount">
              <span class="price_total_tx"><s>{{form.discount}}</s></span>
            </el-form-item>
            <el-form-item
              :label="
                distance != 0
                  ? 'Shipping Charge (' + distance + ' km)'
                  : 'Shipping Charge'
              "
              prop="shipping"
            >
            <span class="price_total_tx" v-if="shippingId == 1">{{form.shipping}}</span>             
              <el-input
                v-model="form.shipping_manual"
                auto-complete="off"
                :min="0"
                class="return_product"
                @change="calculateTotal()"
                :disabled="form.sub_total == '$ 0.00'"
                v-else
              ></el-input>
            </el-form-item>
            <el-row class="mb-2">
              <el-col :xs="12" :sm="12" :md="12" :lg="8">
                <label style="font-weight: bold" class="text_size">Adjust Price</label>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="8">
                <el-select
                  clearable
                  v-model="form.adjustment_type"
                  placeholder="Type"
                  class="tax"
                  @change="calculateTotal()"
                  @clear="clearAdjustprice()"
                >
                  <el-option label="Increment" value="1"> </el-option>
                  <el-option label="Decrement" value="0"> </el-option>
                </el-select>
              </el-col>
              <el-col :xs="4" :sm="4" :md="4" :lg="6" :offset="2" >
                <el-input
              
                  v-model="form.adjustment_price"
                  auto-complete="off"
                  :min="0"
                  class="return_product text-place"
                  :disabled="!form.adjustment_type"
                  @change="calculateTotal()"
                ></el-input>
              </el-col>
            </el-row>
            <el-row class="mb-2">
              <el-col :xs="12" :sm="12" :md="12" :lg="8">
                <label style="font-weight: bold" class="text_size">Tax</label>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="8">
                <el-select
                  filterable
                  v-model="form.tax_per"
                  placeholder="Select"
                  clearable
                  @clear="taxClear()"
                  @change="calculateTotal()"
                  class="tax"
                  :disabled="form.sub_total == '$ 0.00'"
                >
                  <el-option class="add-container" value="">
                    <a class="add-drop-btn" @click="createTax()">
                      <i class="el-icon-circle-plus"></i> Add Tax
                    </a>
                  </el-option>
                  <el-option
                    v-for="(tax, index) in tax_list"
                    :key="index"
                    :label="
                      tax.tax_name + '(' + tax.tax_amount + '%)'
                    "
                    :value="tax.tax_amount"
                    auto-complete="off"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="4" :sm="4" :md="4" :lg="6" :offset="2">
                <el-input
                  v-model="form.tax_total"
                  auto-complete="off"
                  :min="0"
                  class="return_product text-place"
                  disabled
                ></el-input>
              </el-col>
            </el-row>
            <el-form-item label="Total" prop="total">
              <span class="price_total_tx"><b>{{form.total}}</b></span>                        
            </el-form-item>                          
          </el-form>
      </div>
    </div>
    <el-form-item style="margin-top: 25px">
          <el-button
            type="primary"
            size="mini"
            @click="createOrder"
            :disabled=" price_error ||
              loading == true || form.sub_total == '$ 0.00'
                ? true
                : false
            "
            >{{ submit_btn_text }}</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="Directpaynow()"
            :disabled="price_error ||
              loading == true || form.sub_total == '$ 0.00'
                ? true
                : false
            "
            v-if="
              this.create_form == 'order_create'
            "
            >Paynow</el-button
          >
          <el-button
            type="warning"
            size="mini"
            @click.prevent="reset()"
            >Reset</el-button
          >
          <!-- <el-button
            type="warning"
            size="mini"
            @click.prevent="closeOrder()"
            >Close</el-button
          > -->
    </el-form-item>
    </el-form>
     <!--end: Orders Form-->
   </diV>
  </div>
   <!--end: Orders-->
</template>
<script>
//import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
//import { getCityList, getStateList, getCityListByStateId } from '@/api/common'
import {
  searchCustomer,
  getCustomerAddress,
  getWebsite,
  getOrderTerm,
  searchProduct,
  getTax,
  getShippingMethod,
  getShippingCost,
  getDeliveryType,
  createOrderdirect,
  getShipVia,
  getLastCredit
} from "@/api/order";
import {
  getAllCustomers,
  getCustomerCredit,
  getCustomerType,
  getSalesoutsidelist,
  getCustomerMemo
} from "@/api/customer";
import { getProductsForDropdown } from "@/api/product";
import createProduct from "@/components/CreateProduct/add.vue";
import createCustomer from "@/components/CreateCustomer/index.vue";
import CreateAddress from "@/components/CreateAddress/addAddress";
import CreateTerm from "@/components/CreateOrderTerm";
import { globalEvents } from "@/utils/globalEvents";
import { getAllActiveSites } from "@/api/website";
import createTax from "@/components/CreateTax/addTax.vue";
import JwtService from "@/core/services/jwt.service";
import UpdateAddress from "@/components/CreateAddress";
import CreateShipping from "@/components/DropDown/shippingMethod";
import DeliverType from "@/components/DropDown/deliveryType";
import payNow from "@/components/DirectPayment/payNow";
import CreateShipVia from "@/components/DropDown/shipVia";
import scrollToMandatoryFields from "@/helpers/ScrollToMandatoryFields";
import extraPrice from "@/components/OrderCreate/extraPrice";
import { getEstimationDetails } from "@/api/estimation";
export default {
  name: "add-order",
  data() {
    return {
      loading: false,
      loadingCustomer: false,
      error: null,
      user: [],
      bill: true,
      ship: true,
      labelPosition: "right",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      shippingId: 1,
      create_form: "order_create",
      customer_name: null,
      form: new Form({
        customer_id: null,
        customer_type: null,
        customer_typeId: null,
        created_date: new Date(new Date().toLocaleString('en', {timeZone: 'America/Toronto'})),
        delivery_date: new Date(new Date().toLocaleString('en', {timeZone: 'America/Toronto'})),
        billing_address_id: null,
        shipping_address_id: null,
        ship_via: null,
        sale_person: null,
        shiping_method: null,
        order_type: "Regular Order",
        order_by: null,
        type: null,
        term: null,
        sub_total: "$ 0.00",
        subTotal: 0,
        discount: "$ 0.00",
        credit_amount: 0,
        order_status: "waiting_payment",
        website_id: null,
        comments: null,
        tax_per: 13,
        tax_total: "$ 0.00",
        shipping: "$ 0.00",
        total: "$ 0.00",
        inside_price_total: "$ 0.00",
        customer_po: null,
        project_name: null,
        ref_by: null,
        ct_discount: "$ 0.00",
        delivery_type: null,
        shipping_manual: "$ 0.00",
        adjustment_price: "$ 0.00",
        adjustment_type: null,
        order_taken_by: null,
        available_credit: 0.00,
        paymentID: null,
        total_quantity: 0,        
        onsite_contact : null,
        shipping_number : null,
        last_credit : null,
        note : null,
        creditmemo : null,
        products: [
          {
            product_id: null,
            quantity: null,
            regular_price: 0,
            unit_price: 0,
            tax_id: null,
            tax_amount: 0,
            total_price: 0,
            stock_quantity: 0,
            discount: 0,
            tax_list: [],
            extra_discount: 0,
            total: 0,
            total_qtyprice: 0,
            customer_type: null,
            price_type: null,
            inside_per: "",
            type: null,
            customer_discount: 0,
            stock_status: 0,
            inside_product_price: 0,
            price_change : 0,
          },
        ],
      }),
      search: "",
      product_discount: 0,
      addresses: [],
      city_list: [],
      state_list: [],
      customer_list: [],
      product_list: [],
      active_website: [],
      product_price: [],
      billing_address: [],
      shipping_address: [],
      type_list: [],
      term_list: [],
      quantity: [],
      tax_list: [],
      deliveryType: [],
      ouside_salesperson: [],
      searchKeyword: "",
      logo_btn_text: null,
      submit_btn_text: "Create Order",
      title_text: "Create Sales Order",
      api_url: "/order/create",
      response_msg: "Order created successfully.",
      shippingmethod: [],
      shippingvia: [],
      shippingtype: [
        {
          id: 1,
          text: "Single Piece",
        },
        {
          id: 2,
          text: "Bulk",
        },
        {
          id: 3,
          text: "Box",
        },
      ],
      ordertype: [
        {
          id: 1,
          text: "Regular Order",
        },
        {
          id: 2,
          text: "Custom Order",
        },
      ],
      priceType: [
        {
          id: 1,
          text: "Inside",
        },
      ],
      orderStatus: [
        {
          id: "pending",
          text: "Pending Payment",
        },
        {
          id: "processing",
          text: "Processing",
        },
        {
          id: "on-hold",
          text: "On Hold",
        },
        {
          id: "completed",
          text: "Completed",
        },
        {
          id: "cancelled",
          text: "Cancelled",
        },
        {
          id: "refunded",
          text: "Refunded",
        },
        {
          id: "failed",
          text: "Failed",
        },
      ],
      types: ["date"],
      options: {},
      show_product_error: false,
      show_product_qty_error: false,
      show_credit_error: false,
      user_credits: 0.00,
      available_credit : 0.00,
      balance_credit: 0.00,
      current_product_index: null,
      credit_maxtotal: 0,
      ship_cost: 0,
      distance: 0,
      datelable: "Delivery Date",
      role_id: null,
      grandTotal: 0,
      next_path : '/order/list',
      price_loading:false,
      add_product_btn : 0,
      price_error:null, 
      customer_po : false,
      store : false,
      ref_by : false,
      created_date : false,
      billing_address_show : false,
      project_name : false,
      onsite_contact : false,
      order_type : false,
      order_by : false,
      sale_person : false,
      shipping_number : false,
    };
  },
  components: {},
  mounted() {    
    globalEvents.$on("productAdd", (added) => {
      if (this.form.customer_id) {
        //this.loading = true
        this.form.customer_typeId = "";
        this.form.customer_type = "";
        this.customer_list.map((customer) => {
          if (this.form.customer_id == customer.id) {
            if (customer.customer_type) {
              this.form.customer_typeId = customer.type_id;
              this.form.customer_type = customer.customer_type.type_name;
            }
          }
        });
      }
      if (added) {
        this.product_price.push(added);
        if (
          this.form.products[this.form.products.length - 1].product_id == null
        ) {
          this.form.products.splice(-1, 1);
          this.form.products.push({
            product_id: added.id,
            quantity: null,
            unit_price: 0,
            regular_price: 0,
            tax_id: null,
            tax_amount: null,
            total_price: 0,
            stock_quantity: 0,
            discount: 0,
            tax_list: [],
            extra_discount: 0,
            total_qtyprice: 0,
            customer_type: this.form.customer_typeId,
            price_type: null,
            inside_per: "",
            total: 0,
            customer_discount: 0,
            type: null,
            price: 0,
            stock_status: 0,
            inside_product_price: 0,
            price_change : 0,
          });

          this.setProductPrice(this.form.products.length - 1);
        } else {
          this.form.products.push({
            product_id: added.id,
            quantity: null,
            unit_price: 0,
            regular_price: 0,
            tax_id: null,
            tax_amount: null,
            total_price: 0,
            stock_quantity: 0,
            discount: 0,
            tax_list: [],
            extra_discount: 0,
            total_qtyprice: 0,
            customer_type: this.form.customer_typeId,
            price_type: null,
            inside_per: "",
            total: 0,
            customer_discount: 0,
            type: null,
            price: 0,
            stock_status: 0,
            inside_product_price: 0,
            price_change : 0,
          });

          this.setProductPrice(this.form.products.length - 1);
        }
      }
    });
    globalEvents.$on("CustomerAdded", (added) => {
      if (added) {
        this.customer_list.push(added);
        this.form.customer_id = added.id;
        this.form.customer_typeId = added.type_id;
        this.getAllCustomers();
        this.getCustomerAddress();
        this.getCustomerCredit();
      }
    });
    globalEvents.$on("deliveryType", (added) => {
      if (added) {
        this.deliveryType.push(added);
        this.form.delivery_type = added.type_name;
      }
    });
    globalEvents.$on("shipVia", (shipvia) => {
      if (shipvia) {
        this.shippingvia.push(shipvia);
        this.form.ship_via = shipvia.ship_type;
      }
    });
    globalEvents.$on("AddressAdded", (added) => {
      if (added) {
        this.addresses.push(added);
        if (added.type == "billing") {
          this.form.billing_address_id = added.id;
          this.billing_address.push(added);
          this.bill = false;
        } else {
          this.form.shipping_address_id = added.id;
          this.shipping_address.push(added);
          this.ship = false;
        }
        this.addShipping();
      }
    });
    globalEvents.$on("AddTax", (tax) => {
      if (tax) {
        this.tax_list.push(tax);
        this.form.tax_per = tax.tax_id;
      }
    });
    globalEvents.$on("TermAdded", (term) => {
      if (term) {
        this.term_list.push(term);
        this.form.term = term.term_name;
      }
    });
    globalEvents.$on("shippingMethod", (shipping) => {
      if (shipping) {
        this.shippingmethod.push(shipping);
        this.form.shiping_method = shipping.shipping_name;
      }
    });
    
    globalEvents.$on("priceChange", (priceChange,index) => {
      this.form.products[index].customer_type = priceChange.customer_type;
      this.form.products[index].extra_discount = priceChange.extra_discount;
      this.form.products[index].price_type = priceChange.price_type;
      this.form.products[index].price_change  = priceChange.price_change;
      if (priceChange) {
        this.setProductPrice(index);
      }
     
    });
    globalEvents.$on("payment", (pay) => {
      if (pay) {
        this.form.paymentID = pay.id;
        this.api_urls = "order/create/direct";
        console.log(this.form, "formData");
        this.form
          .post(this.api_urls)
          .then((response) => {
            if (response.status) {
              this.$router.push({
                name: "order",
              });
              this.loading = false;
              this.$showResponse("success", response.message);
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error, "error globalEvents");
          });
      }
    });
  },
  watch: {
    //    '$route' (to, from) {
    //    console.log(to, from);
    //  },
    search(value) {
      if (!value) {
        return;
      }
      //this.loading = true
      searchCustomer(value).then((response) => {
        this.customer_list = response.data.data;
        // this.loading = false
      });
      searchProduct(value, []).then((response) => {
        this.product_price = response.data.data;
        // this.loading = false
      });
      this.getCustomerAddress();
    },
  },
  methods: {
    showField(){
      this.show = true;
    },
    DeliveryTypeClear(){
      this.form.delivery_type = '';
    },
    ExtraPrice(product){
      this.$modal.show(
        extraPrice,
        {product:product.row, sel_index : product.$index, shiping_method : this.form.shiping_method},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "350px",
        }
      );
    },
    getShipped() {
      this.ship = true;
      if (this.form.shiping_method == "Store Pickup") {
        this.form.shipping_address_id = "";
        this.form.ship_via = "";
        this.shipping_address = [];
      }
    },
    getType() {
      this.loading = true;
      getCustomerType().then((response) => {
        this.type_list = response.data.data;
        this.loading = false;
      });
    },
    getTax() {
      this.loading = true;
      getTax().then((response) => {
        this.tax_list = response.data.data;
        this.loading = false;
      });
    },
    getSalesoutsidelist() {
      this.loading = true;
      getSalesoutsidelist().then((response) => {
        this.ouside_salesperson = response.data.data;
        this.loading = false;
      });
    },
    shippedChange(method) {
      //this.addShipping();
      if (method != "CTS Delivery") {
        this.form.delivery_type = "";
      }
      if (method != "Store Pickup") {
        this.ship = true;
        this.shipping_address = [];
        this.addresses.map((shipping) => {
          if (shipping.type == "shipping") {
            this.shipping_address.push(shipping);
            this.ship = false;
            if (this.shipping_address.length == 1) {
              this.form.shipping_address_id = shipping.id;
              this.addShipping();
            }
            if (this.shipping_address.length > 1) {
              this.shipping_address = [];
              this.form.shipping_address_id = "";
              this.ship = true;
            }
          }
        });

        if (this.form.shipping_address_id > 0) {
          this.ship = false;
        }
        if (method == "Free Shipping") {
          this.distance = 0;
          this.ship_cost = 0;
          this.form.shipping = "0.00";
          if (this.form.subTotal > 0) {
            this.calculateTotal();
          }
        }
      } else {
        (this.form.shipping_address_id = ""), (this.distance = 0);
        this.ship_cost = 0;
        this.form.shipping = "0.00";
        this.shipping_address = [];
        this.ship = false;
        if (this.form.subTotal > 0) {
          this.calculateTotal();         
        }
      }
    },
    getShippingMethod() {
      this.loading = true;
      getShippingMethod().then((response) => {
        this.shippingmethod = response.data.data;
        this.loading = false;
      });
    },
    UpdateAddress(billing) {
      this.$modal.show(
        UpdateAddress,
        {
          customer_id: this.form.customer_id,
          address: billing[0],
          label: {
            button: "Update",
            title: "Update Address",
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
        }
      );
    },
    setValueNull() {
      this.value_ = null;
      this.form.customer_typeId = "";
      this.form.customer_type = "";
      this.form.website_id = "";
      this.getAllCustomers();
    },
    taxClear() {
      this.tax_per = 0;
    },
    priceTypeClear(index) {
      this.form.products[index].price_type = null;
    },
    shippingClear() {
      this.form.shipping_address_id = null;
      this.distance = 0;
      this.form.shipping = "0.00";
      this.ship_cost = 0;
      this.calculateTotal();
    },

    addTableRow: function () {
      this.counter++;
      this.tableRows.push("Table Row " + this.counter);
    },
    availableQuantity(product_id) {
      let total_stock = this.getStockQuantity(product_id);
      if (this.$route.params.orderId) {
        this.quantity.map((value) => {
          if (value.product_id == product_id) {
            total_stock =
              parseInt(value.quantity) +
              parseInt(this.getStockQuantity(product_id));
          }
        });
      }
      return total_stock;
    },
    availableCredit() {
      let total_credit = parseInt(this.user_credits);
      if (this.form.sub_total > 0) {
        total_credit = parseInt(this.form.sub_total);
        if (this.form.sub_total >= total_credit) {
          this.show_credit_error = true;
        }
      }
      return total_credit;
    },
    isExist(product_id) {
      for (var i = 0; i < this.form.products.length; i++) {
        if (this.form.products[i].product_id == product_id) {
          return true;
        }
      }
      return false;
    },
    reset() {
      this.form.customer_typeId = "";
      this.form.customer_type = "";
      this.form.website_id = "";
      this.form.billing_address_id = "";
      this.form.shipping_address_id = "";
      this.billing_address = [];
      this.shipping_address = [];
      this.product_price = [];
      this.form.products = [];
      this.form.sub_total = "$ 0.00";
      this.form.discount = "$ 0.00";
      this.form.tax_total = "$ 0.00";
      this.form.total = "$ 0.00";
      this.bill = true;
      this.ship = true;
      this.user_credits = "";
      this.$refs["form"].resetFields();
    },
    closeOrder(){
      this.$router.push({path:'/order/list'})
    },
    addressLabel(address) {
      let formattedAddress = "";
      if (address.company_name) {
        formattedAddress += address.company_name + ", ";
      }
      if (address.address_line_1) {
        formattedAddress += address.address_line_1 + ", ";
      }
      if (address.address_line_2) {
        formattedAddress += address.address_line_2 + ", ";
      }
      if (address.city) {
        formattedAddress += address.city_details.city_name + ", ";
      }
      if (address.state) {
        formattedAddress += address.state_details.state_name + ", ";
      }
      if (address.post_code) {
        formattedAddress += address.post_code + ", ";
      }
      return formattedAddress;
    },
    setProductPrice(index) {
      let customerType = this.form.products[index].customer_type;
      let priceType = this.form.products[index].price_type;
      let pid = this.form.products[index].product_id;
      let unit_price = 0;
      if(this.form.products[index].price_change == 0){
        unit_price = this.getProductPriceById(pid, customerType,index);
      }else{
        unit_price = this.getProductChangePriceById(pid,customerType,index);
      }      
      let inside_price = 0;
      if(pid == 71 && this.role_id == 1){
         if(!this.form.products[index].regular_price || this.form.products[index].regular_price == 0){
           this.form.products[index].regular_price = this.getRegularPrice(pid).toFixed(2);
           unit_price = this.form.products[index].regular_price
         }else{
           unit_price = this.form.products[index].regular_price
         }
       }else{
        this.form.products[index].regular_price = this.getRegularPrice(pid).toFixed(2);
       }  
       if(customerType == 2){
        this.form.products[index].regular_price = this.getCostPrice(pid).toFixed(2);
       }    
      let quantity = parseInt(this.form.products[index].quantity? this.form.products[index].quantity: 0);
      //let extra_discount = unit_price * (this.form.products[index].extra_discount / 100)? unit_price * (this.form.products[index].extra_discount / 100) : 0;
      let extra_discount = 0;
      let tax_percentage = 0;
      let unit_tax_amount = 0;
      let tax_id = parseInt(this.form.products[index].tax_id);
      let stock = this.getStockQuantity(pid);
      let wholeQuantity = this.getWholeQuantity(pid);      
      let wholeDiscount = 0;
      if (this.$route.params.orderId) {
        this.quantity.map((value) => {
          if (value.product_id == pid) {
            stock = parseInt(this.getStockQuantity(pid));
          }
        });
      }

      if (pid != "") {
        if (quantity > stock) {
          if (this.form.products[index].stock_status == 0) {
            const h = this.$createElement;
            this.$notify({
              message: h(
                "i",
                {
                  style: "color: red",
                },
                "Product Out of Stock"
              ),
              offset: 200,
              type: "warning",
            });
          }
          this.form.products[index].stock_status = 1;
        } else {
          this.form.products[index].stock_status = 0;
        }
      }

      if (pid) {
        if (quantity == 0) {
          if (stock == 0 || stock == null) {
            const h = this.$createElement;
            this.$notify({
              message: h(
                "i",
                {
                  style: "color: red",
                },
                "Product Out of Stock"
              ),
              offset: 200,
              type: "warning",
            });
          }
          this.form.products[index].quantity = parseInt("1");
          quantity = parseInt("1");
        }
      }
      if (customerType == 4) {
        if (wholeQuantity) {
          if (quantity <= wholeQuantity) {
            unit_price = this.getRegularPrice(pid).toFixed(2);
            this.product_discount = 0;
          }
        }
      }
      if (tax_id) {
        tax_percentage = this.getTaxAmountByTaxId(tax_id, index);
      }
      if (tax_percentage > 0) {
        unit_tax_amount = this.calculateTaxAmount(unit_price, tax_percentage);
      }
      inside_price = this.getInsidePrice(pid);

      let inside = 0;
      if (priceType == 1) {
        if (quantity > 0) {
          inside = (unit_price * inside_price) / 100;
        }
      }
      this.form.products[index].customer_discount =
        wholeDiscount +
        quantity * this.product_discount;
      this.form.products[index].stock_quantity = stock ? stock : "0";
      this.form.products[index].total_qtyprice = (
        quantity * unit_price
      ).toFixed(2);
      this.form.products[index].tax_list = this.getProductTaxListById(pid);
      this.form.products[index].type = this.getProductType(pid);
      this.form.products[index].unit_price = (
        unit_price - extra_discount
      ).toFixed(2);
      this.form.products[index].inside_per = inside_price;
      this.form.products[index].inside_product_price = quantity * inside;
      this.form.products[index].tax_amount = quantity * unit_tax_amount;
      this.form.products[index].total_price = (
        quantity * unit_price -
        quantity * extra_discount
      ).toFixed(2);
      this.form.products[index].price =
        parseInt(this.form.products[index].quantity) * unit_price;
      this.form.products[index].discount =
        quantity * extra_discount +
        wholeDiscount +       
        quantity * this.product_discount;
      this.form.products[index].total =
        quantity * unit_price - quantity * extra_discount;
        this.add_product_btn = 0;
        this.price_error = null;
        if(this.form.products[index].total <= 0){
          this.add_product_btn = 1;
          this.price_error = "* Please Check Product Price"
        }
      this.TotalQuantity();  
      this.calculateTotal();
    },
    getProductPriceById(product_id, customerType,index) {
      let productPrice = 0;
      let Productdiscount = 0;
      this.price_loading = true;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            if (product.regular_price != "" && product.regular_price != null) {
              if (customerType == 1) {
                if (
                  product.contractor_price != "" &&
                  product.contractor_price != null &&
                  product.contractor_price != 0
                ) {
                  productPrice = parseFloat(product.contractor_price);
                  this.form.products[index].extra_discount = product.contractor_discount;
                  Productdiscount = product.regular_price - product.contractor_price;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.contractor_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.contractor_price - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              } else if (customerType == 2) {
                if (
                  product.dealer_amount != "" &&
                  product.dealer_amount != null &&
                  product.dealer_amount != 0
                ) {
                  productPrice = parseFloat(product.dealer_amount);
                  this.form.products[index].extra_discount = product.dealer_discount;
                  Productdiscount = product.regular_price - product.dealer_discount;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.dealer_amount > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.dealer_amount - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              } else if(customerType == 4){
             
                if (
                  product.whole_amount != "" &&
                  product.whole_amount != null &&
                  product.whole_amount != 0
                ) {
                  productPrice = parseFloat(product.whole_amount);
                  this.form.products[index].extra_discount = product.whole_discount;
                  Productdiscount = product.regular_price - product.whole_discount;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.whole_amount > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.whole_amount - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              }else {
                productPrice = parseFloat(product.regular_price);
                this.form.products[index].extra_discount = 0;
                if (
                  product.special_price != "" &&
                  product.special_price != null &&
                  product.special_price != 0
                ) {
                  if (product.regular_price > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount =
                      product.regular_price - product.special_price;
                  }
                }
              }
            } else {
              productPrice = parseFloat(product.cost_price);
              if (
                product.special_price != "" &&
                product.special_price != null &&
                product.special_price != 0
              ) {
                if (product.cost_price > product.special_price) {
                  productPrice = parseFloat(product.special_price);
                  Productdiscount = product.cost_price - product.special_price;
                }
              }
            }
          }
          this.price_loading = false;
        });
      }
      this.product_discount = Productdiscount;
      return productPrice;
    },
    getProductChangePriceById(product_id,customerType,index) {
      let productPrice = 0;
      let Productdiscount = 0;
      this.price_loading = true;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            if (product.regular_price != "" && product.regular_price != null) {
                const product_price = product.regular_price - product.regular_price * (this.form.products[index].extra_discount / 100);               
                productPrice = parseFloat(product_price);
                Productdiscount = product.regular_price - productPrice;
                if (product.special_price != "" && product.special_price != null && product.special_price != 0) {
                  if (productPrice > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount = productPrice - product.special_price;
                  }
                }
                if(customerType == 2){
                const product_price = product.cost_price - product.cost_price * (this.form.products[index].extra_discount / 100);               
                productPrice = parseFloat(product_price);
                Productdiscount = product.cost_price - productPrice;
                if (product.special_price != "" && product.special_price != null && product.special_price != 0) {
                  if (productPrice > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount = productPrice - product.special_price;
                  }
                }
                }
            }else {
              const product_cost_price = product.cost_price - product.cost_price * (this.form.products[index].extra_discount / 100);               
              productPrice = parseFloat(product_cost_price);
              if (
                product.special_price != "" &&
                product.special_price != null &&
                product.special_price != 0
              ) {
                if (productPrice > product.special_price) {
                  productPrice = parseFloat(product.special_price);
                  Productdiscount = productPrice - product.special_price;
                }
              }
            }
            this.price_loading = false;
          }
        });
      }
      this.product_discount = Productdiscount;
      return productPrice;
    },
    getRegularPrice(product_id) {
      let productPrice = 0;
      if (product_id) {
        this.product_price.map((product) => {
          //console.log(index+"--"+product_id+"---"+product.id);
          if (product_id == product.id) {
            console.log(product);
            if (product.regular_price != "" && product.regular_price != null) {
              productPrice = parseFloat(product.regular_price);
            } else {
              productPrice = parseFloat(product.cost_price);
            }
          }
        });
      }

      return productPrice;
    },
    getCostPrice(product_id) {
      let productPrice = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
              productPrice = parseFloat(product.cost_price);            
          }
        });
      }
      return productPrice;
    },

    getProductDiscount(product_id, customerType) {
      let productPrice = 0;

      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            //console.log(product_id+"---"+product.id + "----"+customerType);
            if (customerType == 1) {
              if (product.contractor_price && product.contractor_price > 0) {
                if (product.regular_price && product.regular_price > 0) {
                  if (product.regular_price > product.contractor_price) {
                    productPrice = parseFloat(
                      product.regular_price - product.contractor_price
                    );
                  }
                }
              }
            } else if (customerType == 2) {
              if (product.dealer_amount && product.dealer_amount > 0) {
                if (product.regular_price && product.regular_price > 0) {
                  if (product.regular_price > product.dealer_amount) {
                    productPrice = parseFloat(
                      product.regular_price - product.dealer_amount
                    );
                  }
                }
              }
            }
          }
        });
      }

      return productPrice;
    },

    getWholeQuantity(pid) {
      let whole_quantity = 0;
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            whole_quantity = product.whole_quantity;
          }
        });
      }
      return whole_quantity;
    },
    getWholePrice(pid) {
      let whole_price = 0;
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            if (
              product.whole_amount != "" ||
              product.whole_amount == null ||
              product.whole_amount != 0
            ) {
              whole_price = parseFloat(product.whole_amount);
            } else {
              whole_price = parseFloat(product.regular_price);
            }
          }
        });
      }
      return whole_price;
    },
    getInsidePrice(pid) {
      let inside_price = "";
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            inside_price = parseFloat(product.inside_per);
          }
        });
      }
      return inside_price;
    },
    getStockQuantity(product_id) {
      let stock = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            stock = product.stock_quantity;
          }
        });
      }
      return stock;
    },
    getProductType(product_id) {
      let type = null;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            type = product.unit;
          }
        });
      }
      return type;
    },
    getTaxAmountByTaxId(tax_id, pindex) {
      let tax_amount = 0;
      if (tax_id) {
        this.form.products[pindex].tax_list.map((tax) => {
          if (tax_id == tax.tax_id) {
            tax_amount = parseFloat(tax.tax_amount);
          }
        });
      }
      return tax_amount;
    },
    calculateTaxAmount(unit_product_price, tax_percentage) {
      let tax_amount = 0;
      if (tax_percentage > 0) {
        tax_amount = (unit_product_price * tax_percentage) / 100;
      }
      return tax_amount;
    },
    getProductTaxListById(product_id) {
      let tax_list = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            tax_list = product.tax_list;
          }
        });
      }
      return tax_list;
    },
    searchCustomer(q) {
      if (q !== "") {
        //this.loadingCustomer = true;
        searchCustomer(q)
          .then((response) => {
            //this.loadingCustomer = false;
            this.customer_list = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            //this.loadingCustomer = false;
            this.customer_list = [];
          });
      } else {
        this.customer_list = [];
      }
    },
    searchProduct(q) {
      if (q !== "") {
        //this.loadingProduct = true;
        var productid = { product: this.getProductId(this.form.products) };
        searchProduct(q, productid)
          .then((response) => {
            //this.loadingProduct = false;
            this.product_price = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            //this.loadingProduct = false;

            this.product_price = [];
          });
      }
    },
    getProductId(products) {
      var productid = [];
      for (var product in products) {
        if (products[product]["product_id"]) {
          productid.push(products[product]["product_id"]);
        }
      }
      return productid;
    },
    customFilter(item, queryText) {
      const textOne = item.first_name ? item.first_name.toLowerCase() : "";
      const textTwo = item.last_name ? item.last_name.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    productNameFilter(item, queryText) {
      const textOne = item.description ? item.description.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return textOne.indexOf(searchText) > -1;
    },
    productSkuFilter(item, queryText) {
      const textOne = item.sku ? item.sku.toLowerCase() : "";
      const searchText = queryText ? queryText.toLowerCase() : "";
      return textOne.indexOf(searchText) > -1;
    },
    convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },

    createOrder: function () {
      let errMsg = false;
      let errMsgQty = false;
      let errCredit = false;
      //   if(JwtService.getAttribute()){
      //       let updatedUserdata = JSON.parse(JwtService.getAttribute());
      //     this.form.order_by = updatedUserdata.first_name+' '+updatedUserdata.last_name
      // }
      this.form.products.forEach(function (value) {
        if (value.product_id == null) {
          errMsg = true;
        }
        if (value.quantity == 0) {
          errMsgQty = true;
        }
      });
      if (this.form.total == this.credit_amount) {
        errCredit = true;
      }
      if (errMsg) {
        this.show_product_error = true;
        return false;
      } else {
        this.show_product_error = false;
        if (errMsgQty) {
          this.show_product_qty_error = true;
          return false;
        } else {
          this.show_product_qty_error = false;
        }
      }
      if (errCredit) {
        this.show_credit_error = true;
        return false;
      }

      this.loading = true;
      this.form.delivery_date = this.convert(this.form.delivery_date);
      this.form.created_date = this.convert(this.form.created_date);
      this.form
        .post(this.api_url)
        .then((response) => {
          if (response.status) {
            this.$router.push({
              path: this.next_path,
            });
            this.loading = false;
            this.$showResponse("success", response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          scrollToMandatoryFields(error, this.$refs);
          
        });
    },

    createCustomer() {
      this.$modal.show(
        createCustomer,
        {},
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "inherit",
          clickToClose: false,
          adaptive: true,
          width: "950px",
        }
      );
    },
    createProduct() {
      this.$modal.show(
        createProduct,
        {
          //customer_id: row.customer_address[0].customer_id
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "inherit",
          clickToClose: false,
          adaptive: true,
          width: "1000px",
        }
      );
    },
    CreateShipping() {
      this.$modal.show(
        CreateShipping,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    CreateDeliveryType() {
      this.$modal.show(
        DeliverType,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    createAddress(val) {
      var titleName = "Shipping Address";
      if (val == "1") {
        titleName = "Billing Address";
      }
      this.$modal.show(
        CreateAddress,
        {
          customer_id: this.form.customer_id,
          address_type: val,
          label: {
            button: "Create",
            title: titleName,
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600px",
        }
      );
    },
    getShipVia() {
      this.loading = true;
      getShipVia().then((response) => {
        //console.log(response);
        this.shippingvia = response.data.data;
        this.loading = false;
      });
    },
    getAllCustomers() {
      this.loading = true;
      getAllCustomers().then((response) => {
        //console.log(response);
        this.customer_list = response.data.data.data;
        this.loading = false;
      });
    },
    clearAdjustprice() {
      this.form.adjustment_price = "$ 0.00";
      this.calculateTotal();
    },
    getDeliveryType() {
      this.loading = true;
      getDeliveryType().then((response) => {
        this.deliveryType = response.data.data;
        this.loading = false;
      });
    },
    changeItem: function changeItem(rowId, event) {
      this.selected =
        "rowId: " + rowId + ", target.value: " + event.target.value;
    },
    getOrderTerm() {
      this.loading = true;
      getOrderTerm().then((response) => {
        this.term_list = response.data.data;
        this.loading = false;
      });
    },
    addProduct() {
      let customerType = null;
      this.customer_list.map((customer) => {
        if (this.form.customer_id == customer.id) {
          if (customer.customer_type) {
            customerType = customer.type_id;
          }
        }
      });

      this.form.products.push({
        product_id: null,
        quantity: null,
        unit_price: 0,
        regular_price: 0,
        tax_id: null,
        tax_amount: null,
        total_price: 0,
        stock_quantity: 0,
        discount: 0,
        tax_list: [],
        extra_discount: 0,
        total_qtyprice: 0,
        customer_type: customerType,
        price_type: null,
        inside_per: "",
        total: 0,
        customer_discount: 0,
        type: null,
        price: 0,
        stock_status: 0,
        inside_product_price: 0,
        price_change : 0,
      });
    },
    removeProduct(index) {
      this.form.products.splice(index, 1);
      this.form.products.map((product)=>{
        if(product.total >0){
          this.price_error =  null;
          this.add_product_btn = 0;
        }
      })
     
      this.calculateTotal();
    },
    shippingcostCalculation(distance_km, grandTotal) {
      let shiiping_cost = 0;
      if (distance_km >= 1 && distance_km <= 7 && grandTotal <= 499) {
        shiiping_cost = 19.99;
      } else if (distance_km >= 8 && distance_km <= 15 && grandTotal <= 999) {
        shiiping_cost = 29.99;
      } else if (distance_km >= 16 && distance_km <= 22 && grandTotal <= 1499) {
        shiiping_cost = 39.99;
      } else if (distance_km >= 23 && distance_km <= 30 && grandTotal <= 1999) {
        shiiping_cost = 59.99;
      } else if (distance_km >= 31 && distance_km <= 40 && grandTotal <= 2999) {
        shiiping_cost = 69.99;
      } else if (distance_km >= 41 && distance_km <= 50 && grandTotal <= 3499) {
        shiiping_cost = 89.99;
      } else if (distance_km >= 51 && distance_km <= 60 && grandTotal <= 4499) {
        shiiping_cost = 99.99;
      } else if (distance_km >= 61 && distance_km <= 70 && grandTotal <= 6999) {
        shiiping_cost = 150;
      } else {
        shiiping_cost = 0.0;
      }
      return shiiping_cost;
    },
    calculateTotal() {
      let sub_total = 0;
      let taxPer = this.form.tax_per ? this.form.tax_per : 0;
      let discount = 0;
      let ct_discount = 0;
      let credit = this.form.credit_amount ? this.form.credit_amount : 0;
      let ship_cost = 0;
      let inside_price = 0;
      if(this.form.shiping_method == 'Store Pickup'){
        this.form.products.map((product) => {
          product.inside_product_price = 0;
         // product.inside_per = '';
          product.price_type = null;
        });
      }
      this.form.products.map((product) => {
        sub_total = sub_total + product.total;
        discount = discount + product.discount;
        ct_discount = ct_discount + product.customer_discount;
        inside_price = inside_price + product.inside_product_price;
      });
      let shippingCharge = 0;
      this.form.sub_total = "$ " + parseFloat(sub_total).toFixed(2);
      this.form.subTotal = sub_total + discount;
      this.form.discount = "$ " + parseFloat(discount).toFixed(2);
      if (this.distance > 0) {
        ship_cost = this.shippingcostCalculation(this.distance, sub_total);
      }

      if (ship_cost == 0) {
        this.shippingId = 0;
        this.form.shipping = "$ 0.00";
        var shippingValue = this.form.shipping_manual.replace(/\$|,/g, "") ? this.form.shipping_manual.replace(/\$|,/g, ""): 0.0;
        var shp1 = parseFloat(shippingValue).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
        if (shp1 != null) {
          shippingCharge = parseFloat(shippingValue);
          this.form.shipping_manual = "$ " + parseFloat(shippingValue).toFixed(2);
        } else {
          shippingCharge = 0;
          this.form.shipping_manual = "$ 0.00";
        }
      } else {
        this.shippingId = 1;
        this.form.shipping_manual = "$ 0.00";
        this.form.shipping = "$ " + parseFloat(ship_cost).toFixed(2);

        shippingCharge = this.shippingcostCalculation(this.distance, sub_total);
      }
     
      //var insidePrice = this.form.inside_price_total.replace(/\$|,/g, "") ? this.form.inside_price_total.replace(/\$|,/g, ""): 0.0;
      //inside_price = parseFloat(insidePrice);
      let tax = ((sub_total + shippingCharge + inside_price) * taxPer) / 100;
      let taxcharge = 0;
      this.form.inside_price_total = "$ " + parseFloat(inside_price).toFixed(2);
      this.form.tax_total = "$ " + parseFloat(tax).toFixed(2);
      this.form.ct_discount = "$ " + parseFloat(ct_discount).toFixed(2);
      this.form.total = "$ " + parseFloat(sub_total - credit + taxcharge).toFixed(2);
      var TaxValue = this.form.tax_total.replace(/\$|,/g, "") ? this.form.tax_total.replace(/\$|,/g, ""): 0.0;
      taxcharge = parseFloat(TaxValue);

      let adjustment = 0;
      if (this.form.adjustment_type == 1) {
        var adjust1 = this.form.adjustment_price.replace(/\$|,/g, "") ? this.form.adjustment_price.replace(/\$|,/g, "") : 0.0;
        var adt = parseFloat(adjust1).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
        if (adt != null) {
          this.form.adjustment_price = "$ " + parseFloat(adjust1).toFixed(2);
          adjustment = parseFloat(adjust1);
          tax = ((sub_total + shippingCharge + adjustment + inside_price) * taxPer) / 100;
          this.form.tax_total = "$ " + parseFloat(tax).toFixed(2);
          TaxValue = this.form.tax_total.replace(/\$|,/g, "") ? this.form.tax_total.replace(/\$|,/g, "") : 0.0;
          taxcharge = parseFloat(TaxValue);
          this.form.total = "$ " + parseFloat( sub_total + shippingCharge + inside_price + taxcharge + adjustment).toFixed(2);
        } else {
          this.form.adjustment_price = "$ 0.00";
        }
      } else {
        var adjust2 = this.form.adjustment_price.replace(/\$|,/g, "") ? this.form.adjustment_price.replace(/\$|,/g, ""): 0.0;
        var adj2 = parseFloat(adjust2).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
        if (adj2 != null) {
          adjustment = parseFloat(adjust2);
          this.form.adjustment_price = "$ " + parseFloat(adjust2).toFixed(2);
          this.form.grandTotal = parseFloat(sub_total + shippingCharge + inside_price).toFixed(2);
          var adjustmentvalue = this.getAdjustmentValue(this.form.grandTotal,adjustment);
          tax = ((sub_total + shippingCharge - adjustmentvalue + inside_price) * taxPer) /100;
          this.form.tax_total = "$ " + parseFloat(tax).toFixed(2);
          TaxValue = this.form.tax_total.replace(/\$|,/g, "")? this.form.tax_total.replace(/\$|,/g, ""): 0.0;
          taxcharge = parseFloat(TaxValue);

          this.form.total = "$ " + parseFloat(sub_total + shippingCharge + inside_price + taxcharge - adjustmentvalue).toFixed(2);
        } else {
          this.form.adjustment_price = "$ 0.00";
        }
      }
    },
    TotalQuantity() {
      
      // var quantity_total = this.form.products.reduce(function (sum, product) {             
      //         var quantity = parseFloat(product.quantity);
      //         if (!isNaN(quantity)) {                 
      //             return sum + quantity;
      //         }else{                    
      //           return sum;
      //         }
      //     }, 0);
      //      this.form.total_quantity = quantity_total; 
      
      this.form.total_quantity = this.form.products.length;
    },
    getAdjustmentValue(grandTotal, adjustment) {
      if (adjustment > grandTotal) {
        const h = this.$createElement;
        this.$notify({
          message: h(
            "i",
            {
              style: "color: red",
            },
            "Adjustment Price enter max $" + grandTotal + " value only"
          ),
          offset: 200,
          type: "warning",
        });

        this.form.adjustment_price = "$ " + grandTotal;
        return grandTotal;
      } else {
        return adjustment;
      }
    },
    getCustomerAddress() {
      this.form.billing_address_id = "";
      this.form.shipping_address_id = "";
      this.billing_address = [];
      this.shipping_address = [];
      this.product_price = [];
      this.form.products = [];
      this.distance = "";
      this.form.sub_total = "$ 0.00";
      this.form.discount = "$ 0.00";
      this.form.tax_total = "$ 0.00";
      this.form.shipping = "$ 0.00";
      this.form.shipping_manual = "$ 0.00";
      this.form.ct_discount = "$ 0.00";
      this.form.total = "$ 0.00";
      this.bill = true;
      this.ship = true;
      this.user_credits = 0.00;
      if (this.form.customer_id) {
        //this.loading = true
        this.form.customer_typeId = "";
        this.form.customer_type = "";
        this.customer_list.map((customer) => {
          if (this.form.customer_id == customer.id) {
            if (customer.customer_type) {
              this.form.customer_typeId = customer.type_id;
              this.form.customer_type = customer.customer_type.type_name;
            }
          }
        });
        getCustomerAddress(this.form.customer_id).then((response) => {
          //this.loading = false
          this.addresses = response.data.data;
          this.shipping_address = [];
          this.billing_address = [];
          this.addresses.map((shipping) => {
            if (shipping.type == "shipping") {
              this.shipping_address.push(shipping);
              this.ship = false;
              if (this.shipping_address.length == 1) {
                this.form.shipping_address_id = shipping.id;
              }
              if (this.shipping_address.length > 1) {
                this.shipping_address = [];
                this.form.shipping_address_id = "";
                this.ship = true;
              }
            }

            if (shipping.type == "billing") {
              this.addShipping();
              this.billing_address.push(shipping);
              this.bill = false;
              if (this.billing_address.length == 1) {
                this.form.billing_address_id = shipping.id;
              }
              if (this.billing_address.length > 1) {
                this.billing_address.push(shipping[0]);
                //this.bill = true;
                this.form.billing_address_id = shipping[0].id;
              }
            }
          });
        });
        this.getWebsite();
        this.getCustomerCredit();
        this.getProductsForDropdown();
        this.getLastCredit(this.form.customer_id);
        this.getCustomerMemo();
      }
    },
    getCustomerCredit() {
      this.loading = true;
      getCustomerCredit(this.form.customer_id).then((response) => {
        console.log(response.data.data.available_credit);
        this.loading = false;
        if (response.data.data) {
          this.user_credits = parseFloat(
            response.data.data.approved_total_credit
          ).toFixed(2);
          this.balance_credit = parseFloat(
            response.data.data.balance_showing
          ).toFixed(2);
          this.available_credit = parseFloat(
            response.data.data.available_credit
          ).toFixed(2);
        }
      });
    },
    getWebsite() {
      this.loading = true;
      getWebsite(this.form.customer_id).then((response) => {
        this.form.website_id = parseInt(response.data.data.website_id);
        this.loading = false;
        if (!this.$route.params.estimation_id) {
          this.addProduct();
        }
      });
    },
    getCustomerMemo(){
      this.loading = true;
      getCustomerMemo(this.form.customer_id).then((response) => {
        this.loading = false;
        this.form.creditmemo = response.data.data;
      });
    },
    getProductsForDropdown() {
      this.loading = true;
      getProductsForDropdown().then((response) => {
        this.loading = false;
        this.product_price = response.data.data;
      });
    },
    getLastCredit(customer_id) {
      this.loading = true;
      getLastCredit(customer_id).then((response) => {
        this.loading = false;
        this.form.last_credit = '';
        this.form.note = '';
        if(response.data){
          this.form.last_credit = response.data.last_credit+' days'
          this.form.note = response.data.pay_message;
        }
      });
    },
    getAllActiveSites() {
      this.loading = true;
      getAllActiveSites().then((response) => {
        this.active_website = response.data.data;
        this.loading = false;
      });
    },
    createTax() {
      this.$modal.show(
        createTax,
        {},
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "400",
        }
      );
    },
    CreateTerm() {
      this.$modal.show(
        CreateTerm,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    CreateShipVia() {
      this.$modal.show(
        CreateShipVia,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    addBilling() {
      let billing_id = this.form.billing_address_id;
      this.billing_address = [];
      this.addresses.map((billing) => {
        if (billing.type == "billing") {
          if (billing_id == billing.id) {
            this.billing_address.push(billing);
            this.bill = false;
          }
        }
      });
    },
    addShipping() {
      let shipping_id = this.form.shipping_address_id;
      let store_id = this.form.website_id;
      this.distance = 0;
      if (this.form.shipping_address_id) {
        getShippingCost(store_id, shipping_id).then((response) => {
          if (this.form.shiping_method != "Free Shipping") {
            this.distance = response.data.km;
            if (this.form.subTotal > 0) {
              this.calculateTotal();
            }
          }
        });
      }
      this.shipping_address = [];
      this.addresses.map((shipping) => {
        if (shipping.type == "shipping") {
          if (shipping_id == shipping.id) {
            this.shipping_address.push(shipping);
            this.ship = false;
          }
        }
      });
    },

    Directpaynow: function () {
      // debugger;
     // this.loading = true;
      this.loadingCustomer = true;
      let errMsg = false;
      let errMsgQty = false;
      let errCredit = false;
      this.form.products.forEach(function (value) {
        if (value.product_id == null) {
          errMsg = true;
        }
        if (value.quantity == 0) {
          errMsgQty = true;
        }
      });
      if (this.form.total == this.credit_amount) {
        errCredit = true;
      }
      if (errMsg) {
        this.show_product_error = true;
        return false;
      } else {
        this.show_product_error = false;
        if (errMsgQty) {
          this.show_product_qty_error = true;
          return false;
        } else {
          this.show_product_qty_error = false;
        }
      }
      if (errCredit) {
        this.show_credit_error = true;
        return false;
      }

      //
      this.form.delivery_date = this.convert(this.form.delivery_date);
      this.form.created_date = this.convert(this.form.created_date);
      this.form.errors.add("shiping_method", "");
      this.form.errors.add("shipping_address_id", "");
      this.form.errors.add("billing_address_id", "");
      this.form.errors.add("delivery_type", "");
      this.form.errors.add("order_taken_by", "");     
      
      if (
        !this.form.shiping_method ||
        !this.form.order_taken_by ||        
        !this.form.billing_address_id ||
        (!this.form.delivery_type && this.form.shiping_method != "Store Pickup")
      ) {
        if (!this.form.shiping_method) {
          this.form.errors.add(
            "shiping_method",
            "Shipping Method is required."
          );
        } else {
          this.form.errors.clear('shiping_method')
        }
        if (!this.form.delivery_type) {
          if (this.form.shiping_method != "Store Pickup") {
            this.form.errors.add(
              "delivery_type",
              "delivery type field is required."
            );
          }
        } else {
          this.form.errors.clear('delivery_type')
        }
        if (!this.form.order_by) {
          this.form.errors.add("order_taken_by", "Required Order taken By");
        } else {
          this.form.errors.clear('order_taken_by')
        }
        if (!this.form.shipping_address_id) {
          if (this.form.shiping_method != "Store Pickup") {
            this.form.errors.add(
              "shipping_address_id",
              "Shipping Address is required."
            );
          }
        } else {
          this.form.errors.clear('shipping_address_id')
        }
        if (!this.form.billing_address_id) {
          this.form.errors.add(
            "billing_address_id",
            "Billing Address is required."
          );
        } else {
          this.form.errors.clear('billing_address_id')
        }
        scrollToMandatoryFields(this.form.errors.errors, this.$refs) // Selvan added
      } else {
        let ShippingIdError = false;
        if (!this.form.shipping_address_id) {
          if (this.form.shiping_method != "Store Pickup") {
            ShippingIdError = true;
            this.form.errors.add(
              "shipping_address_id",
              "Shipping Address is required."
            );
          }
        } else {
         this.form.errors.clear('shipping_address_id')
        }
        if (!this.form.billing_address_id) {
          this.form.errors.add(
            "billing_address_id",
            "Billing Address is required."
          );
        } else {
          this.form.errors.clear('billing_address_id')
        }
        if (!this.form.delivery_type) {
          if (this.form.shiping_method != "Store Pickup") {
          this.form.errors.add(
            "delivery_type",
            "delivery type field is required."
          );
          }
        } else {
          this.form.errors.clear('delivery_type')
        }
        if (ShippingIdError == false) {
          let balance_amount = this.form.total.replace(/\$|,/g, "");
          let customer_id = this.form.customer_id;
          createOrderdirect(balance_amount, customer_id)
            .then((response) => {
              if (response.status) {
                let Invoice = response.data.invoice;
                let TermCondition = response.data.termCondition;
                let Credit = response.data.credit;
                this.$modal.show(
                  payNow,
                  {
                    invoice: Invoice,
                    termCondition: TermCondition,
                    customer_id: customer_id,
                    Credit: Credit,
                    formData: this.form,
                  },
                  {
                    draggable: true,
                    transition: "pop-out",
                    height: "auto",
                    clickToClose: true,
                    adaptive: true,
                    width: "450px",
                  }
                );
                // this.loading = false;
              }
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        }
      }
      this.loadingCustomer = false;
    },
  },

  created() {
    this.getTax();
    this.getType();
    this.getShippingMethod();
    this.getSalesoutsidelist();
    this.form.reset();
    if (JwtService.getAttribute()) {
      let updatedUserdata = JSON.parse(JwtService.getAttribute());
      this.role_id = updatedUserdata.role_id;

      //this.form.credit_access = updatedUserdata.first_name+' '+updatedUserdata.last_name
    }
    let orderId = this.$route.params.estimation_id;
    if (parseInt(orderId) > 0) {
      getEstimationDetails(orderId).then((response) => {
       // this.api_url = "/estimation/convert/order";
        this.form = new Form(response.data.data);
        this.customer_name = response.data.data.customer.company_name ? response.data.data.customer.company_name :
          response.data.data.customer.first_name +
          " " +
          (response.data.data.customer.last_name != null
            ? response.data.data.customer.last_name
            : "");
        this.form.products = response.data.data.estimate_items;
        this.addShipping();
        this.searchProduct();
        this.quantity = response.data.data.sorder_items;        
        this.billing_address.push(response.data.data.billing_address_order);
        if (this.form.shiping_method != "Store Pickup") {
          this.shipping_address.push(response.data.data.shipping_address_order);
        }
        this.form.total = "$" + parseFloat(response.data.data.total).toFixed(2);
        this.form.sub_total =
          "$" + parseFloat(response.data.data.sub_total).toFixed(2);
        this.form.inside_price_total =
          "$" + parseFloat(response.data.data.inside_price_total).toFixed(2);
        this.form.discount =
          "$" + parseFloat(response.data.data.discount).toFixed(2);
        this.form.adjustment_type =
          response.data.data.adjustment_type == 1 ? "Increment" : "Decrement";
        this.form.adjustment_price =
          "$" + parseFloat(response.data.data.adjustment_price).toFixed(2);
        if (response.data.data.shipping != 0) {
          this.shippingId = 1;
          this.form.shipping =
            "$" + parseFloat(response.data.data.shipping).toFixed(2);
        } else {
          this.shippingId = 0;
          this.form.shipping_manual =
            "$" + parseFloat(response.data.data.shipping_manual).toFixed(2);
        }
        this.form.ct_discount = "$" + response.data.data.ct_discount;
        this.form.tax_total = "$" + response.data.data.tax_total;
        this.bill = false;
        this.ship = false;
        this.getWebsite();
        getCustomerAddress(this.form.customer_id).then((response) => {
          this.loading = false;
          this.addresses = response.data.data;
        });
        this.getCustomerCredit();
        this.getOrderTerm();
      });
    }
    this.getShipVia();
    this.getAllCustomers();
    this.getAllActiveSites();
    this.getOrderTerm();
    this.getDeliveryType();
  },
  computed: {
    shippingAddresses() {
      return this.addresses.filter((addr) => {
        return addr.type == "shipping";
      });
    },
    billingAddresses() {
      return this.addresses.filter((addr) => {
        return addr.type == "billing";
      });
    },
  },
};
</script>
<style>
.sales_order{
  border-radius: 0;
  padding: 20px;
}
.sales_order .el-form-item__label{
    font-size: 10px !important;
}
.sales_order .el-input__inner{
    font-size: 10px !important;
    
}
.sales_order .el-form .el-input__inner{
  height: 25px !important;
}
.second_text_field {
    border: 1px solid #E3E3E3;
    border-radius: 3px;
    padding: 8px;
}
.text_header_field{
  font-size: 10px;
  text-decoration : underline;
  cursor: pointer;
  font-weight: 400;
}
.credit_title {
 background-color:#EFF8FC;
 padding : 10px;
 min-height : 55px;
}
.text_size{
  font-size: 10px;
}
.price_total_tx{
    font-size: 10px;
    float: right;
    margin-right: 5px;
    font-weight: 600;
}
.text-place .el-input__inner{
  text-align: right !important;
}
.return_product .el-input__inner {
  /* padding-left: 0px;
      padding-right: 8px; */
  border: 1px solid #d4ccdc !important;
  text-align: end !important;
}
.tax .el-icon-arrow-up{
  margin-top: 10px !important;
}
.tax .el-icon-circle-close{
  margin-top: 10px !important;
}
.info-price{
  float:right; 
  color: #000; 
  cursor:pointer;
}
</style>
